import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowId,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { ENUM } from "../Enum";
import { CustomToolbar } from "./TabelCustomToolBar";
import dateFormat from "dateformat";
import search from "./serach";
import CloseIcon from "@mui/icons-material/Close";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";

export interface ReportData {
  DateTime: string;
  Logs: string[];
  RunStatus: string;
  Runid: number;
  _id: string;
  id?: number;
}

const TestResultTabel = () => {
  const url = ENUM.BASIC_API_URL;
  const [logData, setLogData] = React.useState<ReportData[]>();
  const [filterData, setFilterData] = React.useState<ReportData[]>();
  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [logsData, setLogSData] = React.useState<string[]>();
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const getData = async () => {
    setLoading(true);
    const result = await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
        // setLogData(data);
      })
      .catch((error) => console.error(error));
    // let finalTestData: ReportData[] = [];
    // result.map((report: any, index: number) => {
    //   finalTestData.push({ id: index, ...report });
    // });
    setLogData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const onLogHandle = (params: string[]) => {
    setOpen(true);
    setLogSData(params);
  };

  const columns: GridColDef[] = [
    {
      field: "Runid",
      headerName: "Runid",
      width: 250,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", alignItems: "center", ml: "15px" }}
            >
              {params.row.Runid}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "DateTime",
      headerName: "Date & Time",
      width: 400,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
              {dateFormat(params.row.DateTime)}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "Logs",
      headerName: "Logs",
      width: 400,
      renderCell: (params) => {
        return (
          <div onClick={() => onLogHandle(params.row.Logs)}>
            <Grid container spacing={1} sx={{ cursor: "pointer" }}>
              <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
                {params.row.Logs[0]}
              </Grid>
            </Grid>
          </div>
        );
      },
    },
    {
      field: "RunStatus",
      headerName: "RunStatus",
      width: 300,
    },
  ];

  const onFilterData = (value: string[]) => {
    console.log("value", value);
    if (value.length > 1) {
      value.map((data) => {
        const filteredData = search.ActivityLogTableFilterRowsBySearch(
          logData!,
          data
        );
        setFilterData(filteredData);
      });
    } else {
      setFilterData(logData);
    }
  };

  const onDateRangeFilterData = (
    dateRange: DateRange<dayjs.Dayjs>,
    isCnacel: string
  ) => {
    if (isCnacel === "Cancel") {
      setFilterData(undefined);
    } else {
      const filteredData = search.ActivityLogTableFilterRowsByDate(
        logData!,
        dateRange
      );
      setFilterData(filteredData);
    }
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 2,
            width: "75%",
          }}
        >
          <DataGrid
            rows={filterData ? filterData : logData ?? []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            pageSizeOptions={[50, 100]}
            disableRowSelectionOnClick
            loading={loading}
            density="compact"
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              minHeight: "82vh",
              maxHeight: "82vh",
              boxShadow: 5,

              "& .MuiDataGrid-columnHeaders ": {
                backgroundColor: "black",
                color: "white",
                padding: 2,
                fontSize: "20px",
              },
              "& .MuiDataGrid-cell": {
                paddingLeft: "25px",
                fontSize: "16px",
              },
              "&  .MuiDataGrid-root ": {
                "& .MuiDataGrid-cell:focus-within": {
                  outline: " none !important",
                },
                outline: " none !important",
              },
              borderRadius: 6,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
                placement: "bottom-end",
              },
              toolbar: {
                setFilterButtonEl,
                onFilterData,
                setFilterData,
                onDateRangeFilterData,
                cardName: "Blackline to Airtable Migration Activity Logs",
              },
            }}
          />

          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={"lg"}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Log's Details
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              {logsData?.map((log, index) => {
                return (
                  <>
                    <Box sx={{ display: "flex" }}>
                      <Typography gutterBottom sx={{ mr: 3 }}>
                        {index + 1}:
                      </Typography>
                      <Typography gutterBottom sx={{ mr: 3 }}>
                        {typeof log === "string" ? log : JSON.stringify(log)}
                      </Typography>
                    </Box>
                  </>
                );
              })}
            </DialogContent>
          </BootstrapDialog>
        </Box>
      </Box>
    </>
  );
};

export default TestResultTabel;
