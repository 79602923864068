/* eslint-disable no-self-assign */

import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useCallback, useState } from "react";
import ActivityLogFilter from "./RunStausFilter";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import CloseIcon from "@mui/icons-material/Close";
import search from "./serach";

export const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  cardName?: string;
  onFilterData: (value: string[]) => void;
  setFilterData: (value: string[]) => void;
  onDateRangeFilterData: (
    newValue: DateRange<dayjs.Dayjs>,
    isCnacel: string
  ) => void;
}> = ({
  setFilterButtonEl,
  cardName,
  onFilterData,
  setFilterData,
  onDateRangeFilterData,
}) => {
  const FilterData = [
    { id: 1, status: " No Data Updates" },
    { id: 2, status: " New Rows Inserted" },
    { id: 3, status: "Data Import Successful" },
    { id: 4, status: "Data Update Successful" },
    { id: 5, status: " An error occurred during data import/update" },
  ];
  const [selectedActivityType, setSelectedActivityType] = useState<string[]>([
    "",
  ]);
  const currentDate = dayjs();
  const formattedDateStart = currentDate.format("YYYY-MM-DD");
  const currentDateEnd = currentDate.add(7, "day");
  const formattedDateEnd = currentDateEnd.format("YYYY-MM-DD");
  const [value, setValue] = React.useState<DateRange<Dayjs>>([
    dayjs(formattedDateStart),
    dayjs(formattedDateEnd),
  ]);
  const onFilterHandler = (value: string) => {
    if (!selectedActivityType.includes(value)) {
      let data = [...selectedActivityType, value];
      setSelectedActivityType(data);
      setFilterData([]);
      onFilterData(data);
    } else {
      let data = selectedActivityType.filter((data) => data !== value);
      setSelectedActivityType(data);
      setFilterData([]);
      onFilterData(data);
    }
  };

  const onDateChange = (newValue: DateRange<dayjs.Dayjs>) => {
    setValue(newValue);
    onDateRangeFilterData(newValue,"notCancel");
  };

  const handleClose = () => {
    setValue([dayjs(formattedDateStart), dayjs(formattedDateEnd)]);
    onDateRangeFilterData([dayjs(formattedDateStart), dayjs(formattedDateEnd)],"Cancel");
  };

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
          //   backgroundColor: "common.white",
          "& .MuiButton-root": {
            color: "black",
          },
          " .MuiInputBase-root": {
            color: "black",
          },
          " .MuiInputBase-input": {
            color: "black",
            fontSize: "typography.subtitle1",
          },
          "& .MuiIconButton-root": {
            color: "black",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            variant="h5"
            sx={{
              color: "black",
              m: 1,
            }}
          >
            {cardName}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker", "DateRangePicker"]}>
              <DemoItem label="" component="DateRangePicker">
                <DateRangePicker
                  value={value}
                  format="YYYY-MM-DD"
                  slotProps={{ textField: { placeholder: "YYYY-MM-DD" } }}
                  onChange={(newValue) => onDateChange(newValue)}
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "0.4375em",
                    },
                    display: "flex",
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              mt: "-25px",
              color: (theme) => theme.palette.grey[500],
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <GridToolbarContainer sx={{ display: "block", ml: "15px" }}>
            <GridToolbarQuickFilter sx={{ marginLeft: "15px" }} />
            <ActivityLogFilter
              options={FilterData}
              label={"RunStatus Filter"}
              selectedActivityType={selectedActivityType}
              onFilterHandler={onFilterHandler}
            />
            {/* <GridToolbarFilterButton ref={setFilterButtonEl} /> */}
          </GridToolbarContainer>
        </Box>
      </Toolbar>
    </>
  );
};
