import dayjs from "dayjs";
import { ReportData } from "./TestResultTabel";
import dateFormat from "dateformat";
import { DateRange } from "@mui/x-date-pickers-pro";

const ActivityLogTableFilterRowsBySearch = (
  rows: ReportData[],
  searchedVal: string
) => {
  return rows.filter((row) => {
    return row.RunStatus.toString()
      .toLowerCase()
      .trim()
      .includes(searchedVal.toString().toLowerCase().trim());
  });
};

const ActivityLogTableFilterRowsByDate = (
  rows: ReportData[],
  searchedVal: DateRange<dayjs.Dayjs>
) => {
  let result = rows.filter((data) => {
    const date = dayjs(data.DateTime);
    if (searchedVal[0]! === searchedVal[1]!) {
      return date
        .format("DD/MM/YYY")
        .toString()
        .toLowerCase()
        .includes(searchedVal[0]!.format("DD/MM/YYY").toString().toLowerCase());
    } else {
      return date >= searchedVal[0]! && date <= searchedVal[1]!;
    }
  });
  return result;
};

const search = {
  ActivityLogTableFilterRowsBySearch,
  ActivityLogTableFilterRowsByDate,
};

export default search;
