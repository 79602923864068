import Header from "./components/Header";
import CustomizedTables from "./components/DataTabel";
import Footer from "./components/Footer";
import { Typography } from "@mui/material";
import TestResultTabel from "./components/TestResultTabel";

function App() {
  return (
    <>
      <Header />
      <div className="App">
        <div>
          <Typography sx={{marginBottom:"5px"}}></Typography>
          {/* <CustomizedTables /> */}
          <TestResultTabel/>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
