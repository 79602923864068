import React, { useEffect, useState } from "react";

import { AppBar, Toolbar, Typography } from "@mui/material";
import {ReactComponent as LogoMobileSVG} from '../assets/rare-logo-white-mobile.svg'
import "../App.css";
function Footer() {
  return (
    <>
       <div className="footer">
        {/* <h1></h1> */}
      {/* <h1>..</h1> */}
    </div>
    </>
  );
}

export default Footer;
