import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, Checkbox } from "@mui/material";

export const ActivityLogFilter = (props: any) => {
  const { label, options, children, selectedActivityType, onFilterHandler } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: any, value: any) => {
    onFilterHandler(value);
  };
  

  return (
    <div className="customMenuButton">
      {label ? (
        <Button onClick={handleClick}>
          <FilterAltOutlinedIcon />
          {label}
        </Button>
      ) : (
        <div onClick={handleClick}>{children}</div>
      )}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={3}
        sx={{ marginRight: "25px", fontSize: "12px" }}
      >
        {options.map((menuItem: any, index: any) => (
          <MenuItem
            key={index}
            onClick={(event) => handleMenuItemClick(event, menuItem.status)}
          >
            <Checkbox
              checked={
                selectedActivityType &&
                selectedActivityType.includes(menuItem.status)
              }
            />{" "}
            {menuItem.status}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActivityLogFilter;
