import React, { useEffect, useState } from "react";

import { AppBar, Toolbar, Typography } from "@mui/material";
import {ReactComponent as LogoMobileSVG} from '../assets/rare-logo-white-mobile.svg'

function Header() {
  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "black !important",
          height:"50px"
        }}
      >
        <Toolbar>
            <LogoMobileSVG style={{marginTop:"-18px"}}/>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
